import { Partition } from '@snapchat/graphene';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';

export const config: SiteConfiguration = {
  domainName: 'eng.snap.com',

  theme: {},

  globalNavProps: {
    defaultGroupKey: 'snap',
  },
  trackingSettings: {
    cookieDomain: 'snap.com',
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.ENG_BLOG },
      {
        vendor: LoggingVendor.GOOGLE,
        googleTagManagerId: 'GTM-PB72WQ3',
        googleAnalyticsId: 'UA-41740027-61',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'snap-eng-blog',
        dsn: 'https://4ef29882c82f43f090b6201d81899ff9@sentry.sc-prod.net/72',
      },
    ],
  },
  search: true,
};
